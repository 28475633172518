import { PAGE_SIZE, ACCESS_TOKEN, SELECTED_LOCALE ,CURRENT_USER, STUDY_ID_KEY, STUDY_CLOSE_FLAG_KEY} from 'constants/index';
import { notification } from 'antd';
import intl from "react-intl-universal";
import { getStudyCloseFlag, getStudyIdRequest } from './Helpers';

const request = (options, isAddContentType = true, isResultJson=true, ContentType = "application/json" ) => {
    let selectedLocale = localStorage.getItem(SELECTED_LOCALE);
    const headers = new Headers({
        'Accept-Language': (!selectedLocale || selectedLocale === "en-US") ? 'en' : 'jp'
    })
    if (isAddContentType) {
        headers.append('Content-Type', ContentType);
    }
    let currentUserLoginId = 0;
    if (localStorage.getItem(CURRENT_USER)) {
        currentUserLoginId = localStorage.getItem(CURRENT_USER);
    }
    
    if (currentUserLoginId != window.currentUser && window.currentUser > 0 && currentUserLoginId > 0) {
        window.location.href = "/";
        return false;
    }

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    if( typeof options.checkStudyCloseFlag != 'undefined' && options.checkStudyCloseFlag === false){
        headers.append('Not-Check-Study-Close', true)
    }
    // ADD current study-id
    const studyId = getStudyIdRequest();
    headers.append(STUDY_ID_KEY, studyId);
    headers.append(STUDY_CLOSE_FLAG_KEY, getStudyCloseFlag(studyId));

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    const online = window.navigator.onLine;

    if (!online && !options.url.includes("/basic")) {
        notification.error({
            message: intl.get("TYPE.ERROR"),
            description: intl.get("COMMSG003")
        });
        return Promise.reject({});
    }

    return fetch(options.url, options, 10000)
        .then(response => {
            if (!response.ok) {
                // When internet connection error
                if (!online) {
                    notification.error({
                        message: intl.get("TYPE.ERROR"),
                        description: intl.get("COMMSG003")
                    });
                    return false;
                }
                fetchErrorHandler(response.status);
                return Promise.reject(response);
            }
            if (isResultJson){
                return response.json().then(json => {
                    if (!response.ok) {
                        return Promise.reject(json);
                    }
                    return json;
                })
            }else{
                return response;
            }
        })
};

function fetchErrorHandler(status) {
    switch (status) {
        case 401:
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(CURRENT_USER);
            window.currentUser = 0;
            if (window.location.pathname != '/login') {
                window.location.href = "/login";
            }
            break;
        case 413:
            notification.error({
            message: intl.get("TYPE.ERROR"),
            description: intl.get("COMMSG010")
            });
            break;
        case 504:
            notification.error({
                message: intl.get("TYPE.ERROR"),
                description: intl.get("COMMSG004")
            });
            break;

        case 500:
        case 400:
            notification.error({
                message: intl.get("TYPE.ERROR"),
                description: intl.getHTML("COMMSG002")
            });
            break;
        // SC_REQUEST_URI_NOT_ALLOWED_WHEN_STUDY_CLOSE
        case 419:
        case 406:
            notification.error({
                key : 'COMMSG024',
                message: intl.get("TYPE.ERROR"),
                description: intl.getHTML("COMMSG024"),
            });
            setTimeout(() => {
                window.location.href = "/";
            }, 500);
            break;
        default:

            break;
    }
}

export function login(loginRequest) {
    return request({
        url: "/api/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        window.currentUser = 0;
        return Promise.reject("No access token set.");
    }

    return request({
        url: "/api/auth/me",
        method: 'GET'
    });
}


export function getCurrentStudyAdmin() {
    return request({
        url: "/api/auth/study-admin",
        method: 'GET'
    });
}

export function getStudyBasic(studyId) {
    return request({
        url: `/api/study/${studyId}/basic`,
        method: 'GET'
    });
}

export function getListStudy(obj) {
    return request({
        url: "/api/study/list",
        method: 'POST',
        body: JSON.stringify(obj)
    });
}

export function createStudy(studyData) {
    return request({
        url: "/api/study/create",
        method: 'POST',
        body: JSON.stringify(studyData)
    });
}

export function updateStudy(studyUpdateData) {
    return request({
        url: "/api/study/update",
        method: 'POST',
        body: JSON.stringify(studyUpdateData)
    });
}

export function changeCloseFlagStudy(changeCloseFlagrequest) {
    return request({
        url: "/api/study/change-close-flag",
        method: 'POST',
        body: JSON.stringify(changeCloseFlagrequest)
    });
}

export function getStudyDetails(studyId) {
    return request({
        url: `/api/study/${studyId}/detail`,
        method: 'GET'
    });
}

export function getEdcSettingByStudyId(studyId) {
    return request({
        url: "/api/edcsetting/get-edc-setting-by-studyId?studyId=" + studyId,
        method: 'GET'
    });
}

export function UpdateEdcSetting(setting) {
    return request({
        url: "/api/edcsetting/update",
        method: 'POST',
        body: JSON.stringify(setting)
    });
}

export function searchCodelist(objSearchCodelist, page, size) {
    page = page || 0;
    size = size || PAGE_SIZE;
    return request({
        url: "/api/codelist/search-code-list-detail?page=" + page + "&size=" + size,
        method: 'POST',
        body: JSON.stringify(objSearchCodelist)
    });
}

export function getListCrfgroup(studyId) {
    return request({
        url: "/api/crf-group/list/" + studyId,
    });
}

export function createOrUpdateCrfgroup(studyId, crfgroups) {
    return request({
        url: "/api/crf-group/update/" + studyId,
        method: 'POST',
        body: JSON.stringify(crfgroups),
        dataType: "json",
    });
}

export function getListCycleAndVisit(studyId) {
    return request({
        url: "/api/cycle/list/" + studyId,
    });
}


export function updateCycle(studyId, cycle) {
    return request({
        url: "/api/cycle/update/" + studyId,
        method: 'POST',
        body: JSON.stringify(cycle),
        dataType: "json",
    });
}


export function getListPage(studyId, versionId) {
    return request({
        url: "/api/page/list/" + studyId + "?versionId=" + versionId,
        method: 'GET'
    });
}

export function updateCrfPage(crfpage) {
    return request({
        url: "/api/page/update",
        method: 'POST',
        body: JSON.stringify(crfpage)
    });
}

export function createCodeList(codelist) {
    console.log(JSON.stringify(codelist));
    return request({
        url: "/api/codelist/create",
        method: 'POST',
        body: JSON.stringify(codelist)
    });
}

export function updateCodeList(id, codelist) {
    return request({
        url: "/api/codelist/update/" + id,
        method: 'POST',
        body: JSON.stringify(codelist)
    });
}

export function getCodeListById(codeListId) {
    return request({
        url: "/api/codelist/edit/" + codeListId,
        method: 'GET'
    });
}

export function importCodeListToExcel(studyId, codelistsImport) {
    const isAddContentType = false;
const isResultJson = true;
    return request(
        {
            url: "/api/codelist/import-excel/" + studyId,
            method: 'POST',
            body: codelistsImport
        },
        isAddContentType,
        isResultJson
    );
}

export function getCodeListByStudy(studyId) {
    return request({
        url: "/api/codelist/list/" + studyId,
        method: 'GET'
    });
}

export function exportCodeListToExcel(objSearchCodelist) {
    const linkDownload = '/api/codelist/export-excel-code-list-values?studyId=' + objSearchCodelist.studyId 
    + "&codeListIdText=" + objSearchCodelist.codeListIdText 
    + "&name=" + objSearchCodelist.name 
    + "&dbValue=" + objSearchCodelist.dbValue 
    + "&uiValue=" + objSearchCodelist.uiValue;
    const isAddContentType = true;
    const ContentType = 'application/octet-stream';
    const isResultJson = false;
    return request(
        {
            url: encodeURI(linkDownload),
            method: 'GET'
        },
        isAddContentType,
        isResultJson,
        ContentType
    );
}

export function downloadCrfPages(studyId) {
    const linkDownload = '/api/crf-group/download-pages/' + studyId
    const isAddContentType = true;
    const ContentType = 'application/octet-stream';
    const isResultJson = false;
    return request(
        {
            url: linkDownload,
            method: 'GET'
        },
        isAddContentType,
        isResultJson,
        ContentType
    );
}

export function importCrfPages(studyId, crfpagesData) {
    const isAddContentType = false;
    const isResultJson = true;
    return request(
        {
            url: "/api/crf-group/import-crfpages/" + studyId,
            method: 'POST',
            body: crfpagesData
        },
        isAddContentType,
        isResultJson
    );
}

export function getStudyRoles(objSearchRole) {
    return request({
        url: "/api/study-role/list",
        method: 'POST',
        body: JSON.stringify(objSearchRole)
    });
}

export function saveStudyRoles(study, lRoles) {
    return request({
        url: "/api/study-role/update",
        method: 'POST',
        body: JSON.stringify(lRoles)
    });
}


export function getPrivileges(objSearchPrivilege) {
    return request({
        url: "/api/privilege/list",
        method: 'POST',
        body: JSON.stringify(objSearchPrivilege)
    });
}

export function savePrivileges(lPrivileges) {
    return request({
        url: "/api/privilege/update",
        method: 'POST',
        body: JSON.stringify(lPrivileges)
    });
}


export function getPrivilegeSetting(condictionSearch) {
    return request({
        url: "/api/study-role/privilege-setting",
        method: 'POST',
        body: JSON.stringify(condictionSearch)
    });
}

export function savePrivilegeSetting(roleHasPrivileges) {
    return request({
        url: "/api/study-role/update-role-has-privilege",
        method: 'POST',
        body: JSON.stringify(roleHasPrivileges)
    });
}

export function getListSchedules(studyId) {
    return request({
        url: "/api/schedule/list/" + studyId,
        method: 'GET'
    });
}

export function updateSchedule(studyId, schedules) {
    return request({
        url: "/api/schedule/update/" + studyId,
        method: 'POST',
        body: JSON.stringify(schedules),
        dataType: "json",
    });
}

export function getListProperty(studyId, versionId) {
    return request({
        url: "/api/property/list/" + studyId + "?v=" + versionId,
        method: 'GET'
    });
}

export function saveProperty(studyId, crfpages) {
    return request({
        url: "/api/property/update/" + studyId,
        method: 'POST',
        body: JSON.stringify(crfpages),
        dataType: "json",
    });
}

export function deleteProperty(propertyId, type) {
    return request({
        url: "/api/property/delete/" + propertyId + "?type=" + type,
        method: 'GET'
    });
}

export function updatePassword(admin) {
    return request({
        url: "/api/auth/update-password",
        method: 'POST',
        body: JSON.stringify(admin),
        dataType: "json",
    });
}

export function forgetLoginId(data) {
    return request({
        url: "/api/auth/forget-loginid",
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function forgetPassword(data) {
    return request({
        url: "/api/auth/forget-password",
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function resetPassword(data, checkStudyClose = true) {
    return request({
        url: "/api/auth/reset-password",
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
        checkStudyCloseFlag: checkStudyClose
    });
}

export function getListEcs(data) {
    return request({
        url: "/api/ecs/list",
        method: 'POST',
        body: JSON.stringify(data)
    });
}

export function exportEcsListToExcel(searchCondition) {
    const linkDownload = "/api/ecs/export-excel-ecs?studyId=" + searchCondition.studyId
    +"&logicId="+ searchCondition.logicId
    +"&type="+ searchCondition.type
    +"&versionId="+ searchCondition.versionId;
    const isAddContentType = true;
    const ContentType = 'application/octet-stream';
    const isResultJson = false;
    return request(
        {
            url: encodeURI(linkDownload),
            method: 'GET',
        },
        isAddContentType,
        isResultJson,
        ContentType
    );
}

export function saveEcs(ecs) {
    var urlUpdate = "/api/ecs/create";
    if (ecs.id > 0 ){
        urlUpdate = "/api/ecs/update";
    }
    return request({
        url: urlUpdate,
        method: 'POST',
        body: JSON.stringify(ecs)
    });
}

export function getEcs(ecsId) {
    return request({
        url: "/api/ecs/get/"+ecsId,
        method: 'GET',
    });
}

export function getListECSVersions(studyId) {
    return request({
        url: "/api/version/ecs-versions?studyId=" + studyId,
        method: 'GET'
    });
}

export function getListVersions(versionListRequest) {
    return request({
        url: "/api/version/list",
        method: 'POST',
        body: JSON.stringify(versionListRequest)
    });

}

export function getNewVersion(studyId) {
    return request({
        url: "/api/version/get-version-master-data?studyId=" + studyId,
        method: 'GET'
    });
}

export function createNewVersion(version) {
    return request({
        url: "/api/version/update",
        method: 'POST',
        body: JSON.stringify(version)
    });
}

export function importEcsFromExcel(studyId, ecsUploadFile) {
    const isAddContentType = false;
    const isResultJson = true;
    return request(
        {
            url: "/api/ecs/import-excel/" + studyId,
            method: 'POST',
            body: ecsUploadFile
        },
        isAddContentType,
        isResultJson
    );
}

export function getListETraining(studyId, obj) {
    return request({
        url: "/api/etraining/list/" + studyId,
        method: 'POST',
        body: JSON.stringify(obj)
    })
}

export function uploadDocumentETraining(studyId, data) {
    const isAddContentType = false;
    const ContentType = 'application/json';
    const isResultJson = true;
    return request(
        {
            url: "/api/etraining/upload/" + studyId,
            method: 'POST',
            body: data
        },
        isAddContentType,
        isResultJson,
        ContentType
    )
}

export function toggleDeleteETraining(eTrainingId) {
    const isAddContentType = false;
    const ContentType = 'application/json';
    const isResultJson = true;
    return request(
        {
            url: "/api/etraining/toggle-delete/" + eTrainingId,
            method: 'POST',
        },
        isAddContentType,
        isResultJson,
        ContentType
    )
}

export function downloadDocumentETraining(studyId, eTrainingId) {
    const isAddContentType = false;
    const ContentType = 'application/pdf';
    const isResultJson = false;
    return request(
        {
            url: "/api/etraining/download/" + studyId + "/" + eTrainingId,
            method: 'GET',
            responseType: 'blob',
        },
        isAddContentType,
        isResultJson,
        ContentType
    )
}

export function updateETraining(studyId, eTrainings) {
    return request({
        url: "/api/etraining/update/" + studyId,
        method: 'POST',
        body: JSON.stringify(eTrainings),
        dataType: "json",
    });
}

export function getCrfGroupAuditList(crfGroup) {
    return request({
        url: "/api/crf-group/audit-list",
        method: 'POST',
        body: JSON.stringify(crfGroup)
    });
}

export function getEdcsettingAuditList(edcSetting) {
    return request({
        url: "/api/edcsetting/audit-list/" + edcSetting.studyId,
        method: 'POST',
        body: JSON.stringify(edcSetting)
    });
}


export function releaseStudy (studyId) {
    return request({
        url: "/api/release/" + studyId,
        method: 'GET',
    });
}

export function getStudyAdminList(studyId, params) {
    return request({
        url: `/api/${studyId}/study-admin/list`,
        method: 'POST',
        body: JSON.stringify(params)
    })
}

export function updateStudyAdmin(studyId, studyAdmins) {
    return request({
        url: `/api/${studyId}/study-admin/update`,
        method: 'POST',
        body: JSON.stringify(studyAdmins),
        dataType: "json",
    });
}

export function getUsersNotExistStudyAdmin(studyId, data) {
    return request({
        url: `/api/${studyId}/study-admin/users`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function getUserBySuperAdmin(data) {
    return request({
        url: `/api/auth/users`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function createStudyAdmin(userId,data) {
    let currentUserId = 0;
    if (localStorage.getItem(CURRENT_USER)) {
        currentUserId = localStorage.getItem(CURRENT_USER);
    }
    if (userId != currentUserId) {
        console.log (currentUserId);
        console.log (userId);
        window.location.href = "/"; 
      
        return;
    }

    return request({
        url: `/api/auth/user/register`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function getStudyAdminDetails(userRequestId, userId) {
    let currentUserId = 0;
    if (localStorage.getItem(CURRENT_USER)) {
        currentUserId = localStorage.getItem(CURRENT_USER);
    }
    if (userRequestId != currentUserId) {
        console.log (currentUserId);
        console.log (userId);
        window.location.href = "/"; 
        return;
    }
    return request({
        url: "/api/auth/user/" + userId,
        method: 'GET'
    });
}

export function updateAdmin(userId, data) {
    let currentUserId = 0;
    if (localStorage.getItem(CURRENT_USER)) {
        currentUserId = localStorage.getItem(CURRENT_USER);
    }
    if (userId != currentUserId) {
         window.location.href = "/"; 
        return;
    }
    return request({
        url: `/api/auth/user/update`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function downloadStudyRoles(data) {

    const linkDownload = `/api/study-role/download-roles`;
    const isAddContentType = true;
    const ContentType = 'application/json;charset=UTF-8';
    const isResultJson = false;

    return request(
        {
            url: encodeURI(linkDownload),
            method: 'POST',
            body: JSON.stringify(data)
        },
        isAddContentType,
        isResultJson,
        ContentType
    )
}
export function downloadCycleVisit(studyId) {
    const linkDownload = '/api/cycle/download-exel/' + studyId
    const isAddContentType = true;
    const ContentType = 'application/octet-stream';
    const isResultJson = false;
    return request(
        {
            url: linkDownload,
            method: 'GET'
        },
        isAddContentType,
        isResultJson,
        ContentType
    );
}

export function downloadPrivilegeSetting(roleHasPrivileges, fileName) {
    const linkDownload = `/api/study-role/privilege-setting/download-excel/` + fileName;
    const isAddContentType = true;
    const ContentType = 'application/json; charset=utf-8';
    const isResultJson = false;
    return request(
        {
            url: encodeURI(linkDownload),
            method: 'POST',
            body: JSON.stringify(roleHasPrivileges),
        },
        isAddContentType,
        isResultJson,
        ContentType
    );
}

export function downloadSchedules(studyId) {
    const linkDownload = `/api/schedule/download-schedules/${studyId}`;
    const isAddContentType = true;
    const ContentType = 'application/json; charset=utf-8';
    const isResultJson = false;
    return request(
        {
            url: encodeURI(linkDownload),
            method: 'POST',
        },
        isAddContentType,
        isResultJson,
        ContentType
    );
}

/// AUDIT API
export function getCycleAuditList(studyId, data) {
    return request({
        url: `/api/cycle/cycle-audit-list/${studyId}`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function getVisitAuditList(studyId, data) {
    return request({
        url: `/api/cycle/visit-audit-list/${studyId}`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function getStudyAudit(data, checkStudyClose = true) {
    return request({
        url: `/api/study/audit`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
        checkStudyCloseFlag: checkStudyClose
    });
}

export function getPrivilegeAudit(studyId, data) {
    return request({
        url: `/api/privilege/privilege-audit/${studyId}`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    })
}

export function getSuperAdminUserAudit(data) {
    return request({
        url: `/api/auth/user/audit`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function getCodelistAuditList(auditRequest) {
    return request({
        url: "/api/codelist/audit-list",
        method: 'POST',
        body: JSON.stringify(auditRequest)
    });
}

export function getEcsAuditList(auditRequest) {
    return request({
        url: "/api/ecs/audit-list",
        method: 'POST',
        body: JSON.stringify(auditRequest)
    });
}

export function importCycleFromExcel(studyId, file) {
    const isAddContentType = false;
    const isResultJson = true;
    return request(
        {
            url: "/api/cycle/import-excel/" + studyId,
            method: 'POST',
            body: file
        },
        isAddContentType,
        isResultJson
    );
}

export function getStudyAdminAudit(studyId, data) {
    return request({
        url: `/api/${studyId}/study-admin/audit`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}
export function cloneStudy(studyId, studyClone) {
    return request({
        url: `/api/study/${studyId}/clone`,
        method: 'POST',
        body: JSON.stringify(studyClone)
    });
}

export function getStudyRoleAudit(studyId, data) {
    return request({
        url: `/api/study-role/role-audit/${studyId}`,
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",

    });
}

export default request;
