module.exports = ({

    "SM431MSG006": "The block type cannot be changed because the STRATIFICATION has already been set for Item of this block.",

    "SM430MSG002": "The page type cannot be changed because the Consent date has already been set for this page.",

    'SM433P001': 'Please enter ID',
    'SM433P002': 'Please enter Domain ID',
    'SM433P003': 'Please enter Label',
    'SM433MSG001': 'Please enter ID.',
    'SM433MSG002': 'Please enter Item Label.',
    'SM433MSG003': 'Please select a CodeList',
    'SM433MSG004': 'This Item ID is already in use.',
    'SM433MSG011': 'The data length of the item must be greater than or equal to length of DB Value in CodeList.',
    'SM433MSG012': 'The input data of CDMS may be deleted due to the layout change. Are you sure you want to change the layout?',
    'SM433MSG013': 'The data type of item and CodeList are different.',
    'SM433MSG014': 'The layout of the item cannot be changed because the enrollment number is already set for this item.',
    'SM433MSG015': 'The same ID cannot be set in the same Domain.',
    'SM433MSG016': 'The layout of the item cannot be changed because the screening number is already set for this item.',
    'SM433MSG017': 'The layout cannot be set for this item because the ARM_GENERATE is already set for it.',
    'SM433MSG018': 'The layout cannot be set for this item because the IP_GENERATE is already set for it.',
    'SM433MSG019': 'The layout cannot be set to Label/Row No because the RANDOM_KEY is already set for this item.',
    'SM433MSG020': 'The layout cannot be set to Label/Row No because the STRATIFICATION is already set for this item.',
    'SM433MSG021': 'The layout of the item cannot be changed because the CONSENT DATE is already set for this item.',
    'SM433BTN001': 'Property',
    'SM433BTN002': 'Preview',
    'SM433LB001': 'Version',
    'SM433LB002': 'ID',
    'SM433LB003': 'Domain ID',
    'SM433LB004': 'Version',
    'SM433LB005': 'Label',
    'SM433LB006': 'Layout',
    'SM433LB007': 'CodeList',
    'SM433LB008': 'Data Type',
    'SM433LB009': 'Data Length',
    'SM433LB010': 'Required Item',
    'SM433TT001': 'CRF Page',
    'SM433TT002': 'Item Creation',
    'SM433TT003': 'Item Editor',
    'SM433OPT001': 'Display deleted CRF',
    'SM433OPT002': 'Character',
    'SM433OPT003': 'Number',
});