module.exports = ({    
    "SM500.TITLE":"ECS一覧",
    "SM500.BTN.NEW":"新規作成",
    "SM500.BTN.DOWNLOAD":"ダウンロード",
    "SM500.BTN.UPLOAD":"取り込み",
    "SM500.LABEL.UINIT_PAGE":"件表示",
    "SM500.CHECKBOX.DELETE":"削除されたものを表示",
    "SM500.BTN.SEARCH":"検索",
    "SM500.BTN.RESET":"条件クリア",
    "SM500.PLACEHOLDER.LOGIC_ID":"Logic IDを入力してください",

    "SM500.TABLE.HEAD_NO":"No.",
    "SM500.TABLE.HEAD_ID":"Logic ID",
    "SM500.TABLE.HEAD_TYPE":"Type",
    "SM500.TABLE.HEAD_VERSION":"Version",
    "SM500.TABLE.HEAD_MESSAGE":"Message",
    "SM500.TABLE.HEAD_FORCE_SAVE":"Force Save",
    "SM500.TABLE.HEAD_COPY":"コピー",
    "SM500.TABLE.HEAD_AUDIT":"Audit",   

    "SM501.TITLE":"ECSの新規作成",
    "SM501.COPY.TITLE":"ECSの新規作成",   
    "SM502.TITLE":"ECSの更新",   

    "SM501.BTN.SAVE":"保存",
    "SM501.BTN.CANCEL":"キャンセル",
    "SM501.BTN.REQUIRED.TEXT":"必須",
    "SM501.TEXT.CHECK_LOGICAL":"Check Logical",
    "SM501.TEXT.ITEM_ID":"Item ID",
    "SM501.TEXT.LINE":"Line",
    "SM501.TEXT.BLOG_ID":"Block ID",
    "SM501.TEXT.PAGE_ID":"Page ID",
    "SM501.TEXT.VISIT_ID":"Visit ID",
    "SM501.TEXT.UPDATE_USER_NAME":"更新者",
    "SM501.TEXT.DELETE":"削除",
    "SM501.TEXT.UPDATE_DATE":"更新日時",
    
    "SM501.PLACEHOLDER.ITEM_ID":  "Item IDを入力してください",
    "SM501.PLACEHOLDER.BLOG_ID":  "Block IDを入力してください",
    "SM501.PLACEHOLDER.PAGE_ID":  "Page IDを入力してください",
    "SM501.PLACEHOLDER.VISIT_ID":  "Visit IDを入力してください",
    "SM501.PLACEHOLDER.MESSAGE":  "Messageを入力してください",
    "SM501.PLACEHOLDER.CHECK_LOGICAL":  "Check logicalを入力してください",

    "SM501MSG002":  "入力されたLogic IDがすでに使用されています。",
    "SM501MSG003":  "ECSタイプを選択してください。",
    "SM501MSG007" :"ECSを削除します。\nよろしいですか。",
    "SM501MSG005" :"Visit ID、Page ID、Block ID、Item IDのうち、少なくとも一つを入力してください。",
    "SM501MSG001" :"Logic IDを入力してください。",

    "SM504P.TITLE":"Excelファイルの取り込み",
    "SM504P.LABEL.FILE":"ファイル",
    "SM504P.LABEL.REQUIRED":"必須",
    "SM504P.PLACEHOLDER.FILE":"ファイルを選択してください",
    "SM504P.BTN.BROWER":"選択",
    "SM504P.BTN.UPLOAD":"取り込み",
    "SM504P.BTN.CANCEL":"キャンセル",
    "SM504P.NOTE1":"ファイルの形式が異なります。",
    "SM504P.NOTE2":"Excelファイルのみを選択してください。",
    "SM504P.UPLOAD.SUCCESS":"取り込みが完了しました。",
    "SM504P.MSG.VALID.EMPTY":"入力したデータが正しくありません。",
    "SM504P.MSG.VALID.LENGHT":"入力したデータが正しくありません。",
    "SM504P.MSG.VALID.UNIQUE":"入力したデータが正しくありません。",
    "SM504P.MSG.FILE.EMPTY":"ファイルが空白です。他のファイルを選択してください。",
    "SM504P.MSG.VALID.NOT_SEQUENTIAL":"入力したデータが正しくありません。",
    "SM504MSG005":"{1}の長さが{2}以下入力してください。",
    "SM504MSG003": "{1}が必須です。",
    // audit
    "SM503P.AUDIT.TITLE":"の編集履歴",

});