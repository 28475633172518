module.exports = ({
    "CRFGROUP.TITLE": "CRFグループ",
    "CRFGROUP.TEXTID": "グループID",
    "CRFGROUP.TEXTID.PLACEHOLDER": "グループID",
    "CRFGROUP.LABEL": "ラベル",
    "CRFGROUP.TYPE": "種別",
    "CRFGROUP.DELETE": "削除",
    "CRFGROUP.AUDIT": "Audit",
    "CRFGROUP.MODAL.TITLE": "CRFグループを回復したら全ての",
    "CRFGROUP.MODAL.CONTENT.RESTORE": "CRFグループを回復したら全てのCRFページも回復されます。\n よろしいですか。",
    "CRFGROUP.MODAL.CONTENT.DELETE": "CRFグループを削除したら全てのCRFページも削除されます。\nよろしいですか。",
    "CRFGROUP.SUCCESS.TITLE": "CRFグル",
    "CRFGROUP.SUCCESS.CONTENT": "CRFグループが保存されました。",
    "CRFGROUP.TEXTID.MESSAGE.REQUIRED": "グループIDを入力してください。",
    "CRFGROUP.LABEL.MESSAGE.REQUIRED": "ラベルを入力",
    "CRFGROUP.LABEL.PLACEHOLDER": "ラベル",
    "CRFGROUP.MESSAGE.DUPLICATE": "入力されたグループIDがすでに使用されています。",
     //audit
     "CRFGROUP.AUDIT.TABLE.HEADER.NO": "No.",
     "CRFGROUP.AUDIT.TABLE.HEADER.SEQ_NO": "Seq No.",
     "CRFGROUP.AUDIT.TABLE.HEADER.UPD_USER":"更新者",
     "CRFGROUP.AUDIT.TABLE.HEADER.UPD_DATE": "更新日時",
     "CRFGROUP.AUDIT.TITLE": "の編集履歴",   
     "CRFGROUP.AUDIT.TITLE.DEFAULT": "グループ",     
     "CRFGROUP.TEXT.BOTTOM": "※は必須項目です。",
});