import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import intl from "react-intl-universal";
import React, { Component } from 'react';
import './css/fonts.css';
import './App.css';
import { Route, withRouter, Switch } from 'react-router-dom';
import { notification, LocaleProvider } from 'antd';
import _ from 'lodash';

import { getCurrentUser, getCurrentStudyAdmin , getStudyBasic} from 'util/APIUtils';
import { DEFAULT_LOCALE, LISTMENU, NOTIFICATION_ID_DISPLAYED_LIST } from 'constants/index';
import { ACCESS_TOKEN, CURRENT_USER } from 'constants/index';

import NotFound from 'common/NotFound';
import LoadingIndicator from 'common/LoadingIndicator';
import LoadingComponent from 'common/LoadingComponent';
import { currentDateTime, convertJapanDateTime, compareTwoDate } from 'util/Helpers';
import Loadable from 'react-loadable';
import {isSuperAdmin} from "util/Helpers";
import moment from 'moment';
import 'moment/locale/en-au'
import 'moment/locale/ja'
import jaJP from 'antd/lib/locale-provider/ja_JP';
import enUS from 'antd/lib/locale-provider/en_US';

const LoginLayoutRoute = Loadable({
	loader: () => import("common/LoginLayout"),
	loading: LoadingComponent
});
const MainLayoutRoute = Loadable({
	loader: () => import("common/MainLayout"),
	loading: LoadingComponent
});
const Login = Loadable({
	loader: () => import("components/user/login/Login"),
	loading: LoadingComponent
});

const PasswordSetting = Loadable({
	loader: () => import("components/user/password/PasswordSetting"),
	loading: LoadingComponent
});

const ForgetLoginId = Loadable({
	loader: () => import("components/user/forget/ForgetLoginId"),
	loading: LoadingComponent
});
const ForgetPassword = Loadable({
	loader: () => import("components/user/forget/ForgetPassword"),
	loading: LoadingComponent
});
const StudyList = Loadable({
	loader: () => import("components/study/StudyList"),
	loading: LoadingComponent
});
const SuperAdminList = Loadable({
	loader: () => import("components/study/SuperAdminList"),
	loading: LoadingComponent
});
const SuperAdminUserRegister = Loadable({
	loader: () => import("components/study/SuperAdminUserRegister"),
	loading: LoadingComponent
});
const StudyAdminDetail = Loadable({
	loader: () => import("components/study/StudyAdminDetail"),
	loading: LoadingComponent
});
const NewStudy = Loadable({
	loader: () => import("components/study/NewStudy"),
	loading: LoadingComponent
});
const CrfgroupList = Loadable({
	loader: () => import("components/crfgroup/CrfgroupList"),
	loading: LoadingComponent
});

const CycleList = Loadable({
	loader: () => import("components/cycle/CycleList"),
	loading: LoadingComponent
});

const StudyDetail = Loadable({
	loader: () => import("components/study/StudyDetail"),
	loading: LoadingComponent
});
const PageList = Loadable({
	loader: () => import("components/page/PageList"),
	loading: LoadingComponent
});
const EdcSetting = Loadable({
	loader: () => import("components/edcsetting/EdcSetting"),
	loading: LoadingComponent
});
const CodeList = Loadable({
	loader: () => import("components/codelist/CodeList"),
	loading: LoadingComponent
});

const RoleList = Loadable({
	loader: () => import("components/role/StudyRoleList"),
	loading: LoadingComponent
});

const PrivilegeList = Loadable({
	loader: () => import("components/role/PrivilegeList"),
	loading: LoadingComponent
});


const PrivilegeSetting = Loadable({
	loader: () => import("components/role/PrivilegeSetting"),
	loading: LoadingComponent
});

const Schedules = Loadable({
	loader: () => import("components/schedule/Schedules"),
	loading: LoadingComponent
});

const Property = Loadable({
	loader: () => import("components/property/PropertyList"),
	loading: LoadingComponent
});

const EcsList = Loadable({
	loader: () => import("components/ecs/ECSList"),
	loading: LoadingComponent
});

const EditECS = Loadable({
	loader: () => import("components/ecs/ECSNew"),
	loading: LoadingComponent
});

const VersionList = Loadable({
	loader: () => import("components/version/VersionList"),
	loading: LoadingComponent
});

const ETraining = Loadable({
	loader: () => import("components/etraining/ETrainingList"),
	loading: LoadingComponent
});

const UserList = Loadable({
	loader: () => import("components/user/list/UserList"),
	loading: LoadingComponent
});

const StudyRelease = Loadable({
	loader: () => import("components/management/StudyRelease"),
	loading: LoadingComponent
});
const StudyClone = Loadable({
	loader: () => import("components/management/StudyClone"),
	loading: LoadingComponent
});

const Iwrs = Loadable({
	loader: () => import("components/iwrs/Iwrs"),
	loading: LoadingComponent
});

const NotificationMaintenanceList = Loadable({
	loader: () => import("components/notificatonmaintenance/NotificationMaintenanceList"),
	loading: LoadingComponent
});


const NotificationMaintenanceForm = Loadable({
	loader: () => import("components/notificatonmaintenance/NotificationMaintenanceForm"),
	loading: LoadingComponent
});

const stateDefault = {
	currentUser: null,
	currentStudyId : null,
	studySetting: null,
	timeOut: null,
	currentStudy : {},
	isAuthenticated: false,
	isLoading: false
}
class App extends Component {

	constructor(props) {
		super(props);
		console.log("this.props", this.props)
		this.state = {
			currentUser: null,
			currentStudyId : null,
			studySetting: null,
			timeOut: null,
			currentStudy : {},
			isAuthenticated: (localStorage.getItem(ACCESS_TOKEN)) ? true : false,
			isLoading: false
		}
		this.handleLogout 	= this.handleLogout.bind(this);
		this.loadCurrentUser = this.loadCurrentUser.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.initLocale = this.initLocale.bind(this);
		this.initNotification = this.initNotification.bind(this);
		this.timeOut = null;
		this.events = [
			// "load",
			"click",
			"scroll",
		];
		for (var i in this.events) {
			window.addEventListener(this.events[i], this.resetTimeout);
		}
  	}

	async componentDidMount() {
		console.log("Running componentDidMount App.js")
		this.initNotification();
		this.initLocale();
		await this.loadCurrentUser();
		const pathname = this.props.history.location.pathname;
		var regExp = /\/study\/[\d]+/g;
		let array = pathname.match(regExp)
		if (array) {
			let studyId = parseInt(array[0].replace('/study/', ''))
			console.log("App.js is child page study ", {studyId});
			this.setState({currentStudyId : studyId})
			await this.loadStudyBasic(studyId);
		}
	}

	async componentWillReceiveProps(nextProps) {
        const pathname = nextProps.history.location.pathname;
        var regExp = /\/study\/[\d]+/g;
        let array = pathname.match(regExp)
        if (array) {
            let studyId = parseInt(array[0].replace('/study/', ''))
            if (this.state.currentStudyId != studyId) {
            	console.log("Change study ", studyId);
				await this.loadStudyBasic(studyId);
			}
        }
	}
	resetTimeout = () => {
		const url = window.location.pathname;
		let regExp = /\/study\/[\d]+/g;
		let hasCheckTimeOut = url.match(regExp);
		if (url === "/") {
			hasCheckTimeOut = true;
		}

		if (hasCheckTimeOut) {
			let { studySetting } = this.state;
			let timeOut = this.timeOut;
			// console.log("timeOut", timeOut)
			let autoLogoutTime = 30;
			let self = this;
			clearTimeout(timeOut);
			if (studySetting !== null) {
				autoLogoutTime = studySetting.autoLogoutTime;
			}
			let timeToLogout = (autoLogoutTime * 60) * 1000;
			timeOut = setTimeout(function() {
				self.handleLogout();
			}, timeToLogout);
			
			this.timeOut = timeOut;
		}
	}

	async loadStudyBasic(studyId) {
		await getStudyBasic(studyId).then(data => {
			let currentUser = _.cloneDeep(this.state.currentUser);
			let currentStudy = data;
			let studySetting = data.settingResponse;
			
			currentUser.privilegeFlag = data.adminPrivilegeFlag;
			localStorage.setItem('currentUserInfo', JSON.stringify(currentUser));

			this.setState({
				currentStudy: currentStudy,
				studySetting: studySetting,
				currentUser: currentUser
		  	});
			// Neu khong quyen truy cap se bi tu choi && redirect toi man hinh SM200 hoac khong phai la superAdmin.
			if (data.adminPrivilegeFlag === -1 && this.state.currentUser.isSuperAdmin === false) {
				return this.props.history.push("/");
			}
		}).catch(error => {
			console.log("error ", error)
		})
	}

	initLocale(){
		const currentLocale = (localStorage.getItem('selectedLocale')) 
			? localStorage.getItem('selectedLocale') 
			: DEFAULT_LOCALE;
		intl.init({
			currentLocale,
			locales: {
				[currentLocale]: require(`../locales/${currentLocale}`)
			}
		})
		.then(() => {
			// After loading CLDR locale data, start to render
			// this.setState({ initDone: true });
		});
	}

	initNotification() {
		notification.config({
			placement: 'topRight',
			top: 70,
			duration: 3,
		});
	}

  	async loadCurrentUser() {
        window.currentUser = 0;
    	this.setState({  isLoading: true });
    	await getCurrentUser()
      		.then(response => {
				console.log("user infomation : ", response);
                let currentUser = response;
                window.currentUser = response.id;
                currentUser.isSuperAdmin = isSuperAdmin(response.authorities);
				localStorage.setItem(CURRENT_USER, response.id);
				localStorage.setItem('currentUserInfo', JSON.stringify(currentUser));
                
        		this.setState({
          			currentUser: currentUser,
          			isAuthenticated: true,
          			isLoading: false,
				});

				if (response.pwLastchanged === null) {
					return this.props.history.push("/user/password-setting");
				} 

				if (response.pwMustChangedFlag === 0) {
					return this.props.history.push("/user/password-setting?type=2");
				} 
				
				if (response.pwLastchanged) {
					let pwLastChanged = new Date(response.pwLastchanged);
					if (compareTwoDate(currentDateTime(), pwLastChanged) >= 90) {
						return this.props.history.push("/user/password-setting?type=3");
					}
				}
				
      	}).catch(error => {
			this.setState({
				isLoading: false
			});
    	});
	}

  	handleLogout(redirectTo = "/", notificationType = "success", description = "You're successfully logged out.") {
		console.log("Running handleLogout ...")
		localStorage.removeItem(ACCESS_TOKEN);
		localStorage.removeItem(CURRENT_USER);
        localStorage.removeItem('currentStudy');
        window.currentUser = 0 ;
		this.setState(stateDefault);
		this.props.history.push("/");
 	}

  	async handleLogin() {
		console.log("handleLogin")
		await this.initLocale();
		await this.loadCurrentUser();
		// Thuc hien redirect to /users ( neu co quyen SUPER_ADMIN )
		console.log("Running after login success")
		localStorage.removeItem(NOTIFICATION_ID_DISPLAYED_LIST);
		const currentUser = this.state.currentUser
		if (currentUser.pwLastchanged === null) {
			return this.props.history.push("/user/password-setting");
		}

		if (currentUser.pwMustChangedFlag === 0) {
			return this.props.history.push("/user/password-setting?type=2");
		}

		if (currentUser.pwLastchanged) {
			let pwLastChanged = new Date(currentUser.pwLastchanged);
			if (compareTwoDate(currentDateTime(), pwLastChanged) >= 90) {
				return this.props.history.push("/user/password-setting?type=3");
			}
		}
		if (isSuperAdmin(this.state.currentUser.authorities)) {
			return this.props.history.push("/users");
		}
		this.props.history.push("/");
  	}

  	render() {

		if (this.state.isLoading) {
			return <LoadingIndicator isLoading={this.state.isLoading} />
		}
		let currentLocale = (localStorage.getItem('selectedLocale')) ? localStorage.getItem('selectedLocale') : DEFAULT_LOCALE;
		let locale = currentLocale === DEFAULT_LOCALE ? jaJP : enUS;
		jaJP.DatePicker.lang.today = intl.get("TODAY");
		return (
			<LocaleProvider locale={locale}>
			<Switch>
				<LoginLayoutRoute 
					exact path="/login" 
					component={Login} 
					onLogin={this.handleLogin} 
				/>

				<LoginLayoutRoute 
					exact path="/user/forget-login-id" 
					component={ForgetLoginId} 
					intl={intl}
				/>

				<LoginLayoutRoute 
					exact path="/user/forget-password" 
					component={ForgetPassword} 
					intl={intl}
				/>

				<LoginLayoutRoute 
					exact path="/user/password-setting" 
					component={PasswordSetting} 
					handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
					intl={intl}
					authenticated={this.state.isAuthenticated}
					
					// onLogin={this.handleLogin} 
				/>
				<MainLayoutRoute exact path="/" 
					authenticated={this.state.isAuthenticated} 
					component={StudyList} 
					handleLogout={this.handleLogout} 
					currentUser={this.state.currentUser}
					intl={intl}
				/>
				<MainLayoutRoute exact path="/users" 
					authenticated={this.state.isAuthenticated} 
					component={SuperAdminList} 
					handleLogout={this.handleLogout} 
					currentUser={this.state.currentUser}
					intl={intl}
				/>
				<MainLayoutRoute exact path="/users/register" 
					authenticated={this.state.isAuthenticated} 
					component={SuperAdminUserRegister} 
					handleLogout={this.handleLogout} 
					currentUser={this.state.currentUser}
					intl={intl}
				/>
				<MainLayoutRoute exact path="/user/:userId" 
					authenticated={this.state.isAuthenticated} 
					component={StudyAdminDetail} 
					handleLogout={this.handleLogout} 
					currentUser={this.state.currentUser}
					intl={intl}
				/>
				<MainLayoutRoute exact path="/study/new"
					authenticated={this.state.isAuthenticated}
					component={NewStudy}
					handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
					intl={intl}
				/>
				<MainLayoutRoute exact path="/study/:id"
					authenticated={this.state.isAuthenticated}
					component={StudyDetail}
					handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
					intl={intl}
					showMenu={true}
					menuActive={LISTMENU.STUDY}
				/>
				<MainLayoutRoute exact path="/study/:id/edcsetting"
					authenticated={this.state.isAuthenticated}
					component={EdcSetting}
					handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
					showMenu={true}
                    intl={intl}
					containerWrapClass ={'edc-setting-page'}
					menuActive={LISTMENU.EDCSETTING}
					
				/>
				<MainLayoutRoute exact path="/study/:id/codelist"
					authenticated={this.state.isAuthenticated}
					component={CodeList}
					handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
					showMenu={true}
					menuActive={LISTMENU.CODELIST}
					intl={intl}
					containerWrapClass ={'box-CodeList'}
				/>
				<MainLayoutRoute exact path="/study/:id/crfgroup" 
					authenticated={this.state.isAuthenticated} 
					component={CrfgroupList} 
					handleLogout={this.handleLogout} 
					currentUser={this.state.currentUser}
					intl={intl}
					showMenu={true}
					menuActive={LISTMENU.CRFGROUP}
					containerWrapClass={'wrap'}
				/>

				<MainLayoutRoute exact path="/study/:id/cycle-visit" 
					authenticated={this.state.isAuthenticated} 
					component={CycleList} 
					handleLogout={this.handleLogout} 
					currentUser={this.state.currentUser}
					showMenu={true}
					menuActive={LISTMENU.CYCLE_VISIT}
					intl={intl}
					containerWrapClass={'wrap cycle-visit-page'}
				/>
				
				<MainLayoutRoute exact path="/study/:id/pages"
					authenticated={this.state.isAuthenticated}
					component={PageList}
					handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
					intl={intl}
					showMenu={true}
					menuActive={LISTMENU.PAGES}
					containerWrapClass={'wrap-page'}
				/>

                <MainLayoutRoute exact path="/study/:id/role"
                    authenticated={this.state.isAuthenticated}
                    component={RoleList}
                    handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
                    intl={intl}
					showMenu={true}
					menuActive={LISTMENU.ROLE}
                    containerWrapClass={'list-roles-page'}
                />

                <MainLayoutRoute exact path="/study/:id/privilege"
                    authenticated={this.state.isAuthenticated}
                    component={PrivilegeList}
                    handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
                    intl={intl}
					showMenu={true}
					menuActive={LISTMENU.PRIVILEGE}
                    containerWrapClass={'list-roles-page'}
                />

                <MainLayoutRoute exact path="/study/:id/privilege-setting"
                    authenticated={this.state.isAuthenticated}
                    component={PrivilegeSetting}
					handleLogout={this.handleLogout}
                    currentUser={this.state.currentUser}
                    intl={intl}
					showMenu={true}
					menuActive={LISTMENU.PRIVILEGE_SETTING}
                    containerWrapClass={'list-roles-page'}
                />

				<MainLayoutRoute exact path="/study/:id/schedules"
                    authenticated={this.state.isAuthenticated}
                    component={Schedules}
                    handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
                    intl={intl}
					showMenu={true}
					menuActive={LISTMENU.SCHEDULES}
                    containerWrapClass={'list-schedule'}
                />

				<MainLayoutRoute exact path="/study/:id/property"
                    authenticated={this.state.isAuthenticated}
                    component={Property}
					handleLogout={this.handleLogout}
                    currentUser={this.state.currentUser}
                    intl={intl}
					showMenu={true}
					menuActive={LISTMENU.PROPERTY}
                    containerWrapClass={'list-property'}
                />
                <MainLayoutRoute exact path="/study/:id/ecs"
                    authenticated={this.state.isAuthenticated}
                    component={EcsList}
                    handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
                    intl={intl}
					showMenu={true}
					menuActive={LISTMENU.ESC}
                    containerWrapClass={'list-ecs'}
                />
                <MainLayoutRoute exact path="/study/:id/ecs/:ecsId"
					authenticated={this.state.isAuthenticated}
                    component={(props) => <EditECS  {...props} isCopy={false} />}
					handleLogout={this.handleLogout}
                    currentUser={this.state.currentUser}
					intl={intl}
					showMenu={true}
					menuActive={LISTMENU.ESC}
					containerWrapClass ={'box-edit-esc'}
				/>
                 <MainLayoutRoute exact path="/study/:id/ecs/copy/:ecsId"
					authenticated={this.state.isAuthenticated}
                    component={(props) => <EditECS  {...props} isCopy={true} />}
					handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
					showMenu={true}
					menuActive={LISTMENU.ESC}
					intl={intl}
					containerWrapClass ={'box-edit-esc'}
				/>
                  <MainLayoutRoute exact path="/study/:id/version"
                    authenticated={this.state.isAuthenticated}
                    component={VersionList}
                    handleLogout={this.handleLogout}
                    currentUser={this.state.currentUser}
					intl={intl}
					showMenu={true}
					menuActive={LISTMENU.VERSION}
                    containerWrapClass={'list-version'}
                />
				<MainLayoutRoute exact path="/study/:id/etraining"
                    authenticated={this.state.isAuthenticated}
                    component={ETraining}
                    handleLogout={this.handleLogout}
                    currentUser={this.state.currentUser}
					intl={intl}
                    showMenu={true}
					containerWrapClass={'e-Training-page'}
					menuActive={LISTMENU.ETRAINING}
                />
				
				<MainLayoutRoute exact path="/study/:id/list-users"
                    authenticated={this.state.isAuthenticated}
                    component={UserList}
                    handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
                    intl={intl}
					showMenu={true}
					containerWrapClass={'list-users-page'}
					// containerWrapClass={'list-version'}
					menuActive={LISTMENU.USERLIST}
                />

                <MainLayoutRoute exact path="/study/:id/study-release"
                    authenticated={this.state.isAuthenticated}
                    component={StudyRelease}
                    handleLogout={this.handleLogout}
                    currentUser={this.state.currentUser}
                    intl={intl}
                    showMenu={true}
                    menuActive={LISTMENU.STUDY_RELEASE}
                    containerWrapClass={'releasestudy'}
                />
				<MainLayoutRoute exact path="/study/:id/study-clone"
					authenticated={this.state.isAuthenticated}
					component={StudyClone}
					handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
					intl={intl}
					showMenu={true}
					menuActive={LISTMENU.STUDY_CLONE}
					containerWrapClass={'clone-study'}
				/>
				<MainLayoutRoute exact path="/study/:id/iwrs/:iwrs"
					authenticated={this.state.isAuthenticated}
					component={Iwrs}
					handleLogout={this.handleLogout}
					currentUser={this.state.currentUser}
					intl={intl}
					showMenu={true}
					containerWrapClass={'iwrs'}
				/>
				<MainLayoutRoute exact path="/notification-maintenance-list" 
					authenticated={this.state.isAuthenticated} 
					component={NotificationMaintenanceList} 
					handleLogout={this.handleLogout} 
					currentUser={this.state.currentUser}
					intl={intl}
				/>
				<MainLayoutRoute exact path="/notification-maintenance/create" 
					authenticated={this.state.isAuthenticated} 
					component={NotificationMaintenanceForm} 
					handleLogout={this.handleLogout} 
					currentUser={this.state.currentUser}
					intl={intl}
				/>
				<MainLayoutRoute exact path="/notification-maintenance-detail/:notificationId"
					authenticated={this.state.isAuthenticated} 
					component={NotificationMaintenanceForm} 
					handleLogout={this.handleLogout} 
					currentUser={this.state.currentUser}
					intl={intl}
				/>
				<Route path="/force-refresh" component={null} key="force-refresh" />
				<Route path="/*" component={NotFound}></Route>
			</Switch>
			</LocaleProvider>
		);
  	}
}

export default withRouter(App);


